<template>
  <div class="joinUsIndex">
    <div class="top">
      <div class="svgBox">
        <div class="svg" style="z-index: 1;">
          <img src="../../../assets/img/joinUsIndex/1.png">
        </div>
        <div class="svg" style="top: 55px;">
          <img src="../../../assets/img/joinUsIndex/2.png">
        </div>
        <div class="svg" style="top: 55px;">
          <img style="" src="../../../assets/img/joinUsIndex/3.png">
        </div>
        <div class="svg" style="top: 40px;">
          <img src="../../../assets/img/joinUsIndex/4.png">
        </div>
        <div class="textBox">
          <div class="text" style="margin: 0 0 15px 0;">
            <div class="text1" style="margin: 0px auto;">
              <span>歡迎您加入Finger</span>
            </div>
          </div>
          <div class="text">
            <div class="text2" style="margin: 0px auto;">
              <span>請從以下身分中挑選您需要的服務，若是您不確定身分服務中心</span>
            </div>
          </div>
        </div>
      </div>
      <div class="down">
        <div class="tittleBox">
          <div class="tittle" style="margin: 0px auto;">
            <span>商業夥伴</span>
          </div>
        </div>
        <div class="btnBox">
          <div class="btn" style="margin: 0px auto;">
            <div class="btnAllBox">
              <div class="btnLeftBox">
                <div class="imgBox" @click="goBorker">
                  <img src="../../../assets/img/joinUsIndex/btn1.png" alt="">
                  <div class="mask"></div>
                  <div class="imgBoxTittle">
                    <div class="imgTittle">
                      <span>個人經濟</span>
                    </div>
                    <div class="imgSpan">
                      <span>
                        我想要隨時查看股票市場，同時進行股票交易、期貨、基金等...交易內容
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btnLeftBox" style="width: 610px;">
                <div class="imgBox" @click="goBorkers">
                  <img src="../../../assets/img/joinUsIndex/btn2.png" alt="">
                  <div class="mask"></div>
                  <div class="imgBoxTittle">
                    <div class="imgTittle">
                      <span>卷商</span>
                    </div>
                    <div class="imgSpan">
                      <span>
                        我想申請Finger Taring app使用<br/><br/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btnLeftBox">
                <div class="btnRightBox">
                  <div class="imgBox">
                  <img src="../../../assets/img/joinUsIndex/btn3.png" alt="">
                  <div class="mask"></div>
                  <div class="imgBoxTittle">
                    <div class="imgTittle">
                      <span>銀行</span>
                    </div>
                    <div class="imgSpan">
                      <span>
                        我想要隨時查看股票市場，同時進行股票交易、期貨、基金等...交易內容
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                <div class="btnRightBox">
                  <div class="imgBox" @click="goAgency">
                  <img src="../../../assets/img/joinUsIndex/btn1.png" alt="">
                  <div class="mask"></div>
                  <div class="imgBoxTittle">
                    <div class="imgTittle">
                      <span>經紀商</span>
                    </div>
                    <div class="imgSpan">
                      <span>
                        我想要隨時查看股票市場，同時進行股票交易、期貨、基金等...交易內容
                      </span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'JoinUs',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{
    goBorkers(){
      this.$router.push("/joinUs/borkers")
    },
    goBorker(){
      this.$router.push("/joinUs/borker")
    },
    goAgency(){
      this.$router.push("/joinUs/agency")
    },
  },
}
</script>

<style scoped>
*{
  position: relative;
}
.joinUsIndex{
  position: relative;
  width: 100vw;
  height: 100%;
  background: #f9f9f9;
  display: flex;
}
.top{
  width: 100%;
}
.down{
  width: 100%;
}
.svgBox{
  position: relative;
  width: 100vw;
  display: flex;
}
.textBox{
  margin: 54px auto;
}
.text{
  display: flex;
}
.text1{
  font-size: 64px;
  font-family: Inter;
  font-style: Regular;
  color: white;
  /* -webkit-text-stroke: 1px rgb(0, 0, 0); */
  text-shadow: 0px 4px rgba(0, 0, 0, 0.25);
}
.text2{
  font-size: 24px;
  font-family: Inter;
  font-style: Regular;
  color: white;
  text-shadow: 0px 4px rgba(0, 0, 0, 0.25);
}
.svg{
  position: absolute;
  left: 0;
  top: 0;
}
.tittleBox{
  width: 100%;
  display: flex;
}
.tittle{
  font-size: 48px;
}
.btnBox{
  width: 100%;
}
.btn{
  height: 373px;
  display: flex;
}
.btnAllBox{
  margin: 0 auto;
}
.btnLeftBox{
  display: inline-block;
  height: 373px;
  width: 371px;
  margin-right: 10px;
}
.btnRightBox{
  width: 100%;
  height: 182px;
  margin-bottom: 5px;
}
.imgBox{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}
.imgBox img{
  position: absolute;
  left: -5px;
  top: -5px;
  width: 115%;
  height: 110%;
  transition: .5s;
}
.imgBox:hover img{
  position: absolute;
  left: -20px;
  top: -20px;
  width: 125%;
  height: 120%;
  transition: .5s;
}
.mask{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: .5s;
}
.imgBox:hover .mask{
  background-color: rgba(0, 0, 0, 0);
  transition: .5s;
}
.imgBoxTittle{
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 90%;
  color: rgb(255, 255, 255);
  text-shadow: 0px 4px rgba(0, 0, 0, 0.25);
}
.imgTittle{
  font-size: 42px;
}
.imgSpan{
  font-size: 18px;
  line-height: 18px;
}
</style>
